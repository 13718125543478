import React from "react";
import RadarChart from "../radarChart";
import { Slider } from "@mui/material";

export default function AdvisoryTotalRankingWidget({ startups, datasets, labels }) {
  return (
    <div className="flex flex-wrap xl:flex-nowrap gap-5 items-center bg-black rounded-2xl w-full max-w-full">
      <RadarChart datasets={datasets} labels={labels} showLegend={false} />
    </div>
  );
}
