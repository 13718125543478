import React, { useState, useEffect } from "react";
import StandardButton from "../components/standardButton";
import StandardTextfield from "../components/standardTextfield";
import StandardSelect from "../components/standardSelect";
import FlexibleDataFetcher from "../components/flexibileDataFetcher";
import axios from "axios";
import DialogTags1 from "../components/adminStartupDetails/dialogs/dialogTags1Front";
import DialogTags2 from "../components/adminStartupDetails/dialogs/dialogTags2Front";
import { Button, Chip } from "@mui/material";

export default function CompanyDetails() {
  const [company, setCompany] = useState({});
  const [tags, setTags] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [countries, setCountries] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [country, setCountry] = useState(0);
  const [city, setCity] = useState("");
  const [legalName, setLegalName] = useState("");
  const [brandName, setBrandName] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [phone, setPhone] = useState("");
  const [website, setWebsite] = useState("");
  const [description, setDescription] = useState("");

  const [dialogTagsOpen, setDialogTagsOpen] = useState(false);
  const [dialogSectorsOpen, setDialogSectorsOpen] = useState(false);

  const [sectors, setSectors] = useState([]);
  const [selectedSectors, setSelectedSectors] = useState([]);

  // Funzione per caricare i dati dall'API
  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    if (companyId) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/companies/${companyId}`)
        .then((response) => {
          const companyData = response.data;
          setCompany(companyData);
          setCountry(companyData.country_id || 0);
          setCity(companyData.city || "");
          setLegalName(companyData.legal_name || "");
          setBrandName(companyData.brand_name || "");
          setLinkedin(companyData.linkedin_url || "");
          setPhone(companyData.phone_number || "");
          setWebsite(companyData.website || "");
          setDescription(companyData.description || "");
        })
        .catch((err) => {
          setError(err.message || "Failed to fetch company data");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  // Gestione del salvataggio dei tag
  const handleSaveTags = (tags) => {
    setSelectedTags(tags); // Aggiorna lo stato con i nuovi tag selezionati
    console.log("Tags saved:", tags); // IDs e nomi
  };

  // Gestione del salvataggio dei settori
  const handleSaveSectors = (sectors) => {
    setSelectedSectors(sectors); // Aggiorna lo stato con i nuovi settori selezionati
    console.log("Sectors saved:", sectors); // IDs e nomi
  };

  const handleEditUser = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");

      // Assicurati che il payload abbia gli ID dei tag e delle categorie correttamente
      const payload = {
        name: company.name,
        city: city,
        country_id: parseInt(country),
        linkedin_url: linkedin,
        website: website,
        phone_number: phone,
        description: description,
        //tags: selectedTags.map((tag) => tag.id), // Usa gli ID dei tag
        categories: selectedSectors.map((sector) => sector.id), // Usa gli ID delle categorie
      };

      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/companies/${company.id}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("Company updated successfully:", response.data);
    } catch (err) {
      console.error("Failed to update company:", err.response?.data || err.message);
      setError(err.message || "Update failed");
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <div className="bg-black h-full min-h-[100vh] flex p-5">
        <div className="flex flex-col gap-4 h-full bg-[rgba(255,255,255,0.1)] p-5 w-full rounded-2xl">
          <div className="flex items-center justify-between">
            <h1 className="text-6xl font-medium">Company Details</h1>
            {company.logo_url && (
              <img
                src={company.logo_url}
                alt="Company logo"
                className="w-20 h-20 object-cover rounded-2xl"
              />
            )}
          </div>
          {loading ? (
            <div>Loading...</div>
          ) : (
            <>
              <div className="flex gap-4">
                <StandardSelect
                  label="Country"
                  value={country}
                  items={countries}
                  onChange={(e) => setCountry(e.target.value)}
                />
                <StandardTextfield
                  className="w-full"
                  label="City"
                  value={city || ""}
                  onChange={(e) => setCity(e.target.value)}
                />
              </div>
              <div className="flex gap-4">
                <StandardTextfield
                  className="basis-1/2"
                  label="Legal name"
                  value={legalName || ""}
                  onChange={(e) => setLegalName(e.target.value)}
                />
                <StandardTextfield
                  className="basis-1/2"
                  label="Brand name"
                  value={brandName || ""}
                  onChange={(e) => setBrandName(e.target.value)}
                />
              </div>
              <div className="flex gap-4">
                <StandardTextfield
                  className="basis-1/3"
                  label="Linkedin company page"
                  value={linkedin || ""}
                  onChange={(e) => setLinkedin(e.target.value)}
                />
                <StandardTextfield
                  className="basis-1/3"
                  label="Phone"
                  value={phone || ""}
                  onChange={(e) => setPhone(e.target.value)}
                />
                <StandardTextfield
                  className="basis-1/3"
                  label="Website url"
                  value={website || ""}
                  onChange={(e) => setWebsite(e.target.value)}
                />
              </div>
              <StandardTextfield
                label="Company description"
                value={description || ""}
                multiline
                minRows={4}
                onChange={(e) => setDescription(e.target.value)}
              />



              <div className="flex flex-col gap-4 mt-4">
                {/* Chip per i settori selezionati */}
                <div className="flex flex-wrap gap-2">
                  {selectedSectors.map((sector) => (
                    <Chip
                      key={sector.id}
                      label={sector.name}
                      sx={{
                        backgroundColor: "rgb(var(--global-color-primary))",
                        color: "#000",
                        fontWeight: "bold",
                      }}
                    />
                  ))}
                </div>
                <StandardButton
                  text="Edit Sectors"
                  onClick={() => setDialogSectorsOpen(true)}
                  width="150px"
                />
              </div>
            </>
          )}
          <div className="flex justify-center items-center flex-wrap gap-5 mt-6">
            <StandardButton
              variant="outlined"
              text="Reset"
              width="150px"
              onClick={() => {
                setCountry(company.country_id || 0);
                setCity(company.city || "");
                setLegalName(company.legal_name || "");
                setBrandName(company.brand_name || "");
                setLinkedin(company.linkedin_url || "");
                setPhone(company.phone_number || "");
                setWebsite(company.website || "");
                setDescription(company.description || "");
                setSelectedTags([]);
                setSelectedSectors([]);
              }}
            />
            <StandardButton
              variant="filled"
              text="Save"
              width="150px"
              onClick={handleEditUser}
            />
          </div>
        </div>
      </div>

      {/* Dialog per i settori */}
      <DialogTags2
        open={dialogSectorsOpen}
        handleClose={() => setDialogSectorsOpen(false)}
        onSave={handleSaveSectors}
      />

      <FlexibleDataFetcher
        endpoint={"values/countries"}
        onSuccess={(data) => setCountries(data.data || [])}
        onError={(err) => setError(err.message)}
      />
      <FlexibleDataFetcher
        endpoint={"values/innovation-filters"}
        onSuccess={(data) => setTags(data.data.tags || [])}
        onError={(err) => setError(err.message)}
      />
    </React.Fragment>
  );
}
