import React, { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';
import PinIcon from '@mui/icons-material/PushPin'; 
import DownloadIcon from '@mui/icons-material/Download';
import CircularProgress from '@mui/material/CircularProgress'; // Per i donuts placeholder
import axios from 'axios';
import AdvisoryBubbleChartRankingWidget from "../components/widgets/advisoryBubbleChartRanking";
import AdvisoryTotalRankingWidget from "../components/widgets/analysisTotalRanking";


export default function Advisory() {
  // Colonna sinistra: gestione apertura/chiusura
  const [searchOpen, setSearchOpen] = useState(true); // Sempre aperto
  const [startupsList, setStartupsList] = useState([]);
  const [pinnedList, setPinnedList] = useState([]);
  const [selectedInnovation, setSelectedInnovation] = useState(null);
  const [advisoryResults, setAdvisoryResults] = useState(null);
  const [rankingData, setRankingData] = useState(null);
  const [donutsData, setDonutsData] = useState([]);
  const [bubbleChartDataLeft, setBubbleChartDataLeft] = useState(null);
  const [bubbleChartDataRight, setBubbleChartDataRight] = useState(null);
  const [loadingBubbleLeft, setLoadingBubbleLeft] = useState(true);
  const [loadingBubbleRight, setLoadingBubbleRight] = useState(true);
  const [comparisonTableData, setComparisonTableData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [labels, setLabels] = useState([]);


  // Funzione per toggle colonna sinistra
  const handleSearchOpen = () => {
    setSearchOpen(!searchOpen);
  };

  // Ottengo la lista delle innovation "pinned"
  const fetchPinned = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/advisory/consultant/pinned_innovation`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const pinnedIds = response.data.data.map(item => item.innovation_id);
      setPinnedList(pinnedIds);
    } catch (error) {
      console.error("Errore durante il fetch dei pinned:", error);
    }
  };

  // Ottengo la lista completa di startup
  const fetchStartups = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/advisory/consultant/innovation_list`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const rawList = response.data.data; 

      // Riordino la lista portando in cima i "pinned"
      const sortedList = [...rawList].sort((a, b) => {
        const aIsPinned = pinnedList.includes(a.innovation_id);
        const bIsPinned = pinnedList.includes(b.innovation_id);
        if (aIsPinned && !bIsPinned) return -1;
        if (!aIsPinned && bIsPinned) return 1;
        return 0;
      });

      setStartupsList(sortedList);

    } catch (error) {
      console.error("Errore durante il fetch delle startup:", error);
    }
  };

  // Richiama pinned e lista
  const fetchPinnedAndStartups = async () => {
    await fetchPinned();
    await fetchStartups();
  };

  // Pinnare una startup
  const handlePin = async (innovationId) => {
    try {
      const token = localStorage.getItem('token');
      const isPinned = pinnedList.includes(innovationId); // Verifica se è già pinnata
  
      await axios.post(
        `${process.env.REACT_APP_API_URL}/advisory/consultant/pin`,
        { innovation_id: innovationId, pin: !isPinned }, // Invia false se è già pinnata
        { headers: { Authorization: `Bearer ${token}` } }
      );
  
      // Ricarico pinned e lista
      await fetchPinnedAndStartups();
    } catch (error) {
      console.error("Errore durante il pin della startup:", error);
    }
  };
  

  // Chiamo /innovations/{innovationId} per avere logo_url e name
  const fetchInnovationDetails = async (innovationId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/innovations/${innovationId}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setSelectedInnovation(response.data.data);
    } catch (error) {
      console.error("Errore durante il fetch dell'innovation:", error);
    }
  };

  // /advisory/results/by-innovation?company_id=0&innovation_id=xxx
  // per campaign, surveys, status
  const fetchAdvisoryResults = async (innovationId) => {
    try {
      const token = localStorage.getItem('token');
      const url = `${process.env.REACT_APP_API_URL}/advisory/results/by-innovation?company_id=0&innovation_id=${innovationId}`;
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setAdvisoryResults(response.data.data);
    } catch (error) {
      console.error("Errore durante il fetch dei risultati:", error);
    }
  };

  // Ranking
  const fetchRanking = async (innovationId) => {
    try {
      const token = localStorage.getItem('token');
      const url = `${process.env.REACT_APP_API_URL}/advisory/results/ranking?current_innovation_id=${innovationId}`;
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` }
      });
      if (response.data.data && response.data.data.length > 0) {
        setRankingData(response.data.data[0]); 
      } else {
        setRankingData(null);
      }
    } catch (error) {
      console.error("Errore durante il fetch del ranking:", error);
    }
  };

  // Donuts
  const fetchDonuts = async (innovationId) => {
    try {
      const token = localStorage.getItem('token');
      const url = `${
        process.env.REACT_APP_API_URL
      }/advisory/results/by-absolute?company_id=0&innovations_ids%5B%5D=${innovationId}&widget_id=1`;
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` }
      });
      // Abbiamo "projections" e "data"
      const { projections, data } = response.data;

      // Creiamo un array di oggetti { name, mean }
      // name = projection_name, mean = score_mean (convertito a numero).
      // calcoliamo la percentuale
      //  percent = (score_mean / 4) * 100
      const donutsArr = data.map(item => {
        const mean = parseFloat(item.score_mean) || 0;
        const pct = (mean / 4) * 100; 
        return {
          projection_name: item.projection_name,
          score_mean: mean,
          percentage: pct
        };
      });

      setDonutsData(donutsArr);
    } catch (error) {
      console.error("Errore durante il fetch donuts:", error);
    }
  };

  // Download
  const handleDownload = async (innovationId) => {
    try {
      const token = localStorage.getItem('token');
      const url = `${process.env.REACT_APP_API_URL}/advisory/results/download?innovations_ids%5B%5D=${innovationId}&widget_id=1`;
  
      // Chiediamo a axios di restituirci un "blob"
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
        responseType: 'blob' // <--- importantissimo!
      });
  
      // Creiamo un URL locale per il blob
      const downloadUrl = window.URL.createObjectURL(response.data);
  
      // Creiamo un link <a> e lo simuliamo per far partire il download
      const link = document.createElement('a');
      link.href = downloadUrl;
  
      // Se vuoi un nome di file generico, imposta:
      link.setAttribute('download', 'report.csv'); 
      // Oppure .xlsx, .csv, .zip, in base al tipo di file.
  
      document.body.appendChild(link);
      link.click();
  
      // Rimuoviamo il link temporaneo e il blob URL
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
      
    } catch (error) {
      console.error("Errore durante il download:", error);
    }
  };
  

  // Al click di una startup, carico info + results + ranking + donuts
  const handleStartupClick = async (innovationId) => {
    await fetchInnovationDetails(innovationId);
    await fetchAdvisoryResults(innovationId);
    await fetchRanking(innovationId);
    await fetchDonuts(innovationId);
  };

  // useEffect iniziale: carico pinned e startup
  // poi seleziono la prima
  useEffect(() => {
    const loadAll = async () => {
      await fetchPinnedAndStartups();
    };
    loadAll();
  }, []);

  // Se ho caricato la lista e non ho nulla selezionato, seleziono la prima
  useEffect(() => {
    if (startupsList.length > 0 && !selectedInnovation) {
      const firstId = startupsList[0].innovation_id;
      handleStartupClick(firstId);
    }
  }, [startupsList]); 


  // Bubble Charts
  useEffect(() => {
    const fetchBubbleCharts = async () => {
      if (!selectedInnovation || !selectedInnovation.id) {
        console.error("Nessuna startup selezionata");
        return;
      }
  
      const innovationId = selectedInnovation.id; // Prendi l'id della startup selezionata
  
      // Bubble Chart Left
      try {
        const bubbleLeftResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/advisory/results/by-absolute`,
          {
            params: {
              company_id: 0,
              innovations_ids: [innovationId], // Passa l'ID qui
              widget_id: 2,
            },
          }
        );
        const { projections: projectionsLeft, data: dataLeft } = bubbleLeftResponse.data;
        const bubbleDataLeft = prepareBubbleChartData(projectionsLeft, dataLeft);
        setBubbleChartDataLeft(bubbleDataLeft);
        setLoadingBubbleLeft(false);
      } catch (err) {
        console.error("Errore nel fetch del Bubble Chart Left:", err);
        setLoadingBubbleLeft(false);
      }
  
      // Bubble Chart Right
      try {
        const bubbleRightResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/advisory/results/by-absolute`,
          {
            params: {
              company_id: 0,
              innovations_ids: [innovationId], // Passa l'ID qui
              widget_id: 3,
            },
          }
        );
        const { projections: projectionsRight, data: dataRight } = bubbleRightResponse.data;
        const bubbleDataRight = prepareBubbleChartData(projectionsRight, dataRight);
        setBubbleChartDataRight(bubbleDataRight);
        setLoadingBubbleRight(false);
      } catch (err) {
        console.error("Errore nel fetch del Bubble Chart Right:", err);
        setLoadingBubbleRight(false);
      }
    };
  
    fetchBubbleCharts();
  }, [selectedInnovation]);

  const prepareBubbleChartData = (projections, data) => {
    const projectionName1 = projections[0].projection_name; // Etichetta fuori dal grafico
    const projectionName2 = projections[1].projection_name; // Titolo dentro il grafico

    // Mappa per le startups
    const innovations = {};

    data.forEach(item => {
      const innovationId = item.innovation_id;
      const innovationName = item.innovation_description;
      const projectionId = item.projection_id;
      let scoreMean = parseFloat(item.score_mean);

      let scoreSd = item.score_sd ? parseFloat(item.score_sd) : 0.3;

      if (!innovations[innovationId]) {
        innovations[innovationId] = {
          innovation_id: innovationId,
          innovation_description: innovationName,
          scores: {
            x: null,
            y: null,
            sdX: null,
            sdY: null
          }
        };
      }

      const projectionOrder = projections.find(p => p.projection_id === projectionId).projection_order;

      if (projectionOrder === 'X') {
        innovations[innovationId].scores.x = (scoreMean / 4) * 10;
        innovations[innovationId].scores.sdX = (scoreSd / 4) * 100;
      } else if (projectionOrder === 'Y') {
        innovations[innovationId].scores.y = (scoreMean / 4) * 10;
        innovations[innovationId].scores.sdY = (scoreSd / 4) * 100;
      }
    });

    // Prepara i datasets per il grafico
    const colorPalette = [
      { backgroundColor: 'rgba(0, 113, 115, .5)', borderColor: 'rgb(0, 113, 115)' },
      { backgroundColor: 'rgba(152, 238, 172, .5)', borderColor: 'rgb(152, 238, 172)' },
      { backgroundColor: 'rgba(1, 161, 127, .5)', borderColor: 'rgb(1, 161, 127)' },
    ];

    let colorIndex = 0;
    const datasets = [];
    const innovationsList = []; // Lista delle startups con colori

    for (const innovationId in innovations) {
      const innovation = innovations[innovationId];
      const x = innovation.scores.x || 0;
      const y = innovation.scores.y || 0;
      const sdX = innovation.scores.sdX || 0;
      const sdY = innovation.scores.sdY || 0;
      const z = (sdX + sdY) / 2; // Calcola z come media di sdX e sdY

      const color = colorPalette[colorIndex % colorPalette.length];

      datasets.push({
        label: innovation.innovation_description,
        data: [{
          x: x,
          y: y,
          r: z
        }],
        backgroundColor: color.backgroundColor,
        borderColor: color.borderColor
      });

      // Aggiungi l'innovazione alla lista con il colore
      innovationsList.push({
        innovation_id: innovationId,
        innovation_description: innovation.innovation_description,
        color: color.borderColor
      });

      colorIndex++;
    }

    return {
      outsideLabel: projectionName1,
      title: projectionName2,
      datasets: datasets,
      innovationsList: innovationsList
    };
  };

  // Terza Sezione

  // useEffect per chiamare fetchComparisonData
useEffect(() => {
    const fetchComparisonData = async (innovationId) => {
      try {
        if (!innovationId) {
          console.warn("Nessuna startup selezionata per il confronto");
          return;
        }
  
        const token = localStorage.getItem("token");
  
        // Prima chiamata: ottieni lista degli ID
        //console.log("Fetch compare_to_innovation per innovationId:", innovationId);
        const compareResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/advisory/consultant/compare_to_innovation`,
          { params: { innovation_id: innovationId }, headers: { Authorization: `Bearer ${token}` } }
        );
  
        const comparisonIds = compareResponse.data.data.map(item => item.innovation_id);
        //console.log("Lista degli ID di confronto:", comparisonIds);
  
        const allIds = [innovationId, ...comparisonIds];
        //console.log("Tutti gli ID da confrontare:", allIds);
  
        // Seconda chiamata: ottieni dati radar
        //console.log("Fetch dati radar con gli ID:", allIds);
        const radarResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/advisory/results/by-absolute`,
          { params: { company_id: 0, innovations_ids: allIds, widget_id: 1 }, headers: { Authorization: `Bearer ${token}` } }
        );
  
        const { projections, data } = radarResponse.data;
        //console.log("Projections ricevute:", projections);
        //console.log("Data ricevuta:", data);
  
        const radarLabels = prepareRadarLabels(projections);
        //console.log("Etichette Radar preparate:", radarLabels);
  
        const radarData = prepareChartData(data, projections);
        //console.log("Dati Radar Chart preparati:", radarData);
  
        const tableData = prepareComparisonTable(data, projections);
        //console.log("Dati della tabella di confronto preparati:", tableData);
  
        // Aggiorna gli stati
        setLabels(radarLabels);
        setChartData(radarData);
        setComparisonTableData(tableData);
      } catch (error) {
        console.error("Errore nel fetch dei dati di confronto:", error);
      }
    };
  
    if (selectedInnovation && selectedInnovation.id) {
      console.log("Chiamata fetchComparisonData da useEffect per innovationId:", selectedInnovation.id);
      fetchComparisonData(selectedInnovation.id);
    }
  }, [selectedInnovation]);
  

  const prepareComparisonTable = (data, projections) => {
    const projectionNames = projections.map(projection => projection.projection_name);
    const tableData = data.reduce((acc, item) => {
      if (!acc[item.innovation_id]) {
        acc[item.innovation_id] = {
          innovation_description: item.innovation_description,
          scores: Array(projectionNames.length).fill("--"),
        };
      }
  
      const projectionIndex = projections.findIndex(p => p.projection_id === item.projection_id);
      if (projectionIndex >= 0) {
        acc[item.innovation_id].scores[projectionIndex] = parseFloat(item.score_mean).toFixed(2);
      }
  
      return acc;
    }, {});
  
    return Object.values(tableData);
  };

  const prepareChartData = (data, projections) => {
    const groupedData = {};
  
    data.forEach(item => {
      if (!groupedData[item.innovation_id]) {
        groupedData[item.innovation_id] = {
          label: item.innovation_description,
          data: Array(projections.length).fill(0),
        };
      }
  
      const projectionIndex = projections.findIndex(p => p.projection_id === item.projection_id);
      if (projectionIndex >= 0) {
        groupedData[item.innovation_id].data[projectionIndex] = parseFloat(item.score_mean);
      }
    });
  
    return Object.values(groupedData).map(item => ({
      label: item.label,
      data: item.data,
    }));
  };

  const prepareRadarLabels = (projections) => {
    return projections.map(projection => projection.projection_name);
  };
  

  // Helper: prendi "overall score" e "overall rank" dal rankingData
  // Se non ho rankingData, uso placeholder.
  const overallScore = rankingData ? parseFloat(rankingData.score_mean).toFixed(1) : "--";
  const overallRank = rankingData ? rankingData.ranking : "--";

  return (
    <div className="p-5 flex justify-center items-center h-full max-h-full overflow-y-scroll bg-[#191919] rounded-2xl m-2">
      <div className="p-5 flex items-center justify-center w-full h-full max-h-full ">
        
        {/* Struttura a 2 colonne: sinistra (menu) e centro (contenuto principale) */}
        <div className="flex flex-col gap-5 max-w-full max-h-full w-full h-full">

          {/* Header */}
          <div className="flex justify-between items-center w-full">
            <h1 className="text-6xl font-medium">Ranking - Startup</h1>
          </div>
          
          {/* Flex container che contiene le 2 colonne: sinistra e centro */}
          <div className="flex gap-5 w-full max-w-full max-h-[700px]">
            
            {/* COLONNA DI SINISTRA */}
            <div className="flex gap-5 w-full max-w-full h-full max-h-full">
              
              <div className={`
                h-full flex flex-col items-center bg-black p-5 rounded-2xl overflow-scroll
                ${searchOpen ? 'w-[400px] justify-start' : 'w-fit justify-center'}
              `}>
                {
                  !searchOpen && (
                    <SearchIcon onClick={handleSearchOpen} sx={{cursor: 'pointer'}}/>
                  )
                }
                {
                  searchOpen && (
                    <div className="flex flex-col gap-2 w-full">
                      <div className="flex justify-end w-full">
                        <CloseIcon onClick={handleSearchOpen} sx={{cursor: 'pointer'}} />
                      </div>
                      <div className="flex flex-col gap-2 w-full h-full">
                        {
                          startupsList.map((startup) => {
                            const isPinned = pinnedList.includes(startup.innovation_id);
                            return (
                              <div
                                key={startup.innovation_id}
                                className={`
                                  p-2 flex flex-col gap-1 w-full
                                  rounded-2xl font-semibold cursor-pointer
                                  bg-black hover:bg-[rgb(255,255,255,.1)]
                                  ${isPinned ? 'border border-[#c5ff55]' : ''} 
                                `}
                                onClick={() => handleStartupClick(startup.innovation_id)}
                              >
                                <div className="flex flex-row">
                                  <div className="flex w-full justify-between items-center">
                                    <div>
                                      <p className="text-sm m-0">{startup.innovation_title}</p>
                                    </div>
                                    <PinIcon
                                      sx={{
                                        cursor: 'pointer',
                                        marginRight: '8px',
                                        color: isPinned ? '#c5ff55' : '#fff', // Colore aggiornato
                                      }}
                                      onClick={(e) => {
                                        e.stopPropagation(); // Previeni l'attivazione del click del genitore
                                        handlePin(startup.innovation_id); // Passa l'ID della startup
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        }
                      </div>
                    </div>
                  )
                }
              </div>

              {/* COLONNA CENTRALE */}
              <div className={`
                flex flex-col gap-5 w-full h-full max-h-full
                ${searchOpen ? "max-w-[calc(100%-504px)]" : "max-w-[calc(100%-168px)]"}
              `}>

                {/* Sezione 1: Info startup + dati da by-innovation + pulsante Download */}
                <div className="bg-black p-5 rounded-2xl flex flex-row justify-between items-center">
                  
                  {/* Info Startup */}
                  <div className="flex items-center gap-5">
                    <div className="w-16 h-16 bg-gray-600 flex items-center justify-center rounded-md overflow-hidden">
                      {selectedInnovation && selectedInnovation.logo_url ? (
                        <img
                          src={selectedInnovation.logo_url}
                          alt="Startup Logo"
                          className="object-cover w-full h-full"
                        />
                      ) : (
                        <span>Logo</span>
                      )}
                    </div>
                    <div className="flex flex-col">
                      <span className="font-semibold text-lg">
                        {selectedInnovation ? selectedInnovation.name : "Nome Startup"}
                      </span>
                      <span className="text-sm">
                        {selectedInnovation ? `ID: ${selectedInnovation.id}` : "Direct Payments..."}
                      </span>
                    </div>
                  </div>

                  {/* Punteggi e rank (advisoryResults) */}
                  <div className="flex gap-10 items-center">
                    <div className="flex flex-col items-center">
                      <span>Nr. Campaigns</span>
                      <span>
                        {advisoryResults ? advisoryResults.total_campaigns : 0}
                      </span>
                    </div>
                    <div className="flex flex-col items-center">
                      <span>Surveys Completed</span>
                      <span>
                        {advisoryResults ? advisoryResults.total_completed_surveys : 0}
                      </span>
                    </div>
                    <div className="flex flex-col items-center">
                      <span>Status Updated</span>
                      <span>
                        {advisoryResults ? advisoryResults.status : 'N/A'}
                      </span>
                    </div>

                    {/* Bottone di download */}
                    {selectedInnovation && (
                      <button
                        onClick={() => handleDownload(selectedInnovation.id)}
                        style={{
                          backgroundColor: '#c5ff55',
                          color: '#000',
                          padding: '8px 12px',
                          borderRadius: '8px',
                          border: 'none',
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        <DownloadIcon sx={{ marginRight: '4px' }} />
                        Download
                      </button>
                    )}
                  </div>
                </div>

                {/* Sezione 2: Score e rank */}
                <div className="bg-black p-5 rounded-2xl">
                  <div className="flex justify-center gap-20">
                    <div className="flex flex-col items-center">
                      <span className="text-6xl font-bold">
                        {overallScore}
                      </span>
                      <span>Overall score</span>
                    </div>
                    <div className="flex flex-col items-center">
                      <span className="text-6xl font-bold">
                        {overallRank}
                      </span>
                      <span>Overall rank</span>
                    </div>
                    <div className="flex flex-col items-center">
                      {/* Placeholder "Category rank" */}
                      <span className="text-6xl font-bold">--</span>
                      <span>Category rank</span>
                    </div>
                  </div>

                  {/* Donuts (Market readiness, Risk mitigation, etc.) */}
                  <div className="flex justify-center gap-6 mt-8 flex-wrap justify-between">
                    {donutsData.map((item, idx) => {
                      const roundedPct = Math.round(item.percentage);
                      return (
                        <div
                          key={`donut-${idx}`}
                          className="flex flex-col items-center"
                        >
                          <CircularProgress
                            variant="determinate"
                            value={roundedPct}
                            size={80}
                            sx={{
                              color: '#c5ff55',
                              marginBottom: '8px'
                            }}
                          />
                          <span className="text-sm font-medium">
                            {roundedPct}%
                          </span>
                          <span className="text-xs">
                            {item.projection_name}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>

                {/* Sezione 3: Heatmap / Customer Category Filter (placeholder) */}
                {/* Widget per i Bubble Charts */}
                <div className="bg-black p-5 rounded-2xl">
                  <h3 className="text-xl mb-2">Customer Category Filter</h3>
                  {loadingBubbleLeft || loadingBubbleRight ? (
                    <p>Loading Bubble Charts...</p>
                  ) : (
                    <AdvisoryBubbleChartRankingWidget
                      bubbleChartDataLeft={bubbleChartDataLeft}
                      bubbleChartDataRight={bubbleChartDataRight}
                      hiddenLabel={true}
                    />
                  )}
                </div>

                {/* Sezione 4: Radar chart e tabella di confronto */}
                <div className="bg-black p-5 rounded-2xl flex flex-col gap-5">
                  <h3 className="text-xl mb-2">Radar Chart Comparison</h3>

                  {comparisonTableData.length > 0 && chartData.length > 0 && labels.length > 0 ? (
                    <div>
                      {comparisonTableData.map((startup, index) => {
                        // Trova il dataset specifico per la startup corrente
                        const startupDataset = chartData.find(dataset => dataset.label === startup.innovation_description);
                      
                        if (!startupDataset) {
                          console.warn(`Nessun dataset trovato per: ${startup.innovation_description}`);
                          return null;
                        }
                      
                        return (
                          <AdvisoryTotalRankingWidget
                            key={startup.innovation_id} // Chiave univoca per React
                            startups={[startup]} // Passa i dati della startup corrente
                            datasets={[startupDataset]} // Passa il dataset specifico
                            labels={labels} // Etichette comuni
                          />
                        );
                      })}
                    </div>
                  ) : (
                    <p>Loading Radar Charts...</p>
                  )}

                  <div className="overflow-x-auto">
                    <table className="min-w-full text-left text-white">
                      <thead>
                        <tr>
                          <th className="p-2">Dimension</th>
                          {comparisonTableData.map((item, idx) => (
                            <th key={idx} className="p-2">{item.innovation_description}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {labels.map((label, idx) => (
                          <tr key={idx}>
                            <td className="p-2">{label}</td>
                            {comparisonTableData.map((item, idx2) => (
                              <td key={idx2} className="p-2">{item.scores[idx]}</td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
